<template>
  <div class="exchange-modal">
    <div :class="['exchange-main', isH5 ? 'popup-main' : '']">
      <div class="exchange-close" @click="closeExchangeModal">
        <img src="@/assets/images/close.png" alt="" />
      </div>
      <template v-if="!hasExchange">
        <div class="exchange-title">{{ $t("myCard.exchangeCoupon") }}</div>
        <div>
          <p class="p">{{ $t("myCard.exchangeCode") }}</p>
          <input
            type="text"
            :placeholder="$t('myCard.exchangeCodeTip')"
            v-model="code"
          />
        </div>
        <span class="exchange-btn" @click="exchange">{{ $t('myCard.exchange') }}</span>
      </template>
      <template v-else>
        <div class="exchange-title">{{ $t("myCard.checkCoupon") }}</div>
        <div class="p">{{ $t("myCard.hasCoupon") }}</div>
        <div class="coupon-list">
          <div class="each flex" style="width: 100%">
            <p
              v-if="
                couponOption.max_discount_price &&
                couponOption.max_discount_price[couponCurrentCode]
              "
            >
              {{ $t("myCard.maxNum") }}
              {{ couponCurrentCode === "KRW" ? "₩" : couponCurrentCode
              }}{{ couponOption.max_discount_price[couponCurrentCode] }}
            </p>
            <div>
              <div class="c-name">
                <span>{{ couponOption.name }} </span>
                <div
                  v-if="false"
                  class="c-question"
                  @click.stop="showCouponInfo(couponOption)"
                >
                  <img
                    src="@/assets/images/mycard/question-circle.png"
                    alt=""
                  />
                </div>
              </div>
              <p
                v-if="
                  couponOption.price_condition &&
                  couponOption.price_condition[couponCurrentCode]
                "
                class="min-num"
              >
                {{
                  $t("myCard.useType", {
                    amount:
                      couponCurrentCode === "KRW"
                        ? "₩" + couponOption.price_condition[couponCurrentCode]
                        : couponCurrentCode +
                          couponOption.price_condition[couponCurrentCode],
                  })
                }}
              </p>
              <p class="time">
                {{ $t("myCard.expireAt")
                }}{{
                  formatDate(couponOption.expire_at, "yyyy-MM-dd hh:mm:ss")
                }}
              </p>
            </div>
            <div class="c-num">
              <p>
                <em class="share-tag" v-if="couponOption.can_share === 1">{{ $t("myCard.share") }}</em>
                <i>{{ 100 - couponOption.discount }}%</i
                >{{ $t("myCard.disCount") }}
              </p>
            </div>
          </div>
        </div>
        <span class="exchange-btn" @click="closeExchangeModal">{{ $t("toast.comfirmBtn2") }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import { exchangeCode } from "@/api/mycard";

export default {
  name: "exchange",
  props: {
    couponCurrentCode: {
      default: {},
    },
    isH5: {
      default: false
    }
  },
  data() {
    return {
      code: "",
      hasExchange: false,
      couponOption: {},
    };
  },
  methods: {
    closeExchangeModal() {
      this.$emit("closeExchangeModal", false);
    },
    showCouponInfo() {
      this.$emit("showCouponInfo", this.couponOption);
    },
    formatDate(dateNum, fmt) {
      fmt ? "" : (fmt = "yyyy-MM-dd hh:mm:ss");
      const date = new Date(Number(dateNum) * 1000);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.$parent.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    exchange() {
      if (!this.code.length) {
        this.$parent.message = this.$t("myCard.exchangeTip");
      } else {
        this.$parent.showLoading = true;
        exchangeCode({ code: this.code })
          .then((res) => {
            this.$parent.showLoading = false;
            this.code = "";
            this.couponOption = res.data;
            this.hasExchange = true;
          })
          .catch((err) => {
            this.$parent.message = err;
            this.$parent.showLoading = false;
          });
      }
    },
  },
};
</script>
