var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exchange-modal"},[_c('div',{class:['exchange-main', _vm.isH5 ? 'popup-main' : '']},[_c('div',{staticClass:"exchange-close",on:{"click":_vm.closeExchangeModal}},[_c('img',{attrs:{"src":require("@/assets/images/close.png"),"alt":""}})]),(!_vm.hasExchange)?[_c('div',{staticClass:"exchange-title"},[_vm._v(_vm._s(_vm.$t("myCard.exchangeCoupon")))]),_c('div',[_c('p',{staticClass:"p"},[_vm._v(_vm._s(_vm.$t("myCard.exchangeCode")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],attrs:{"type":"text","placeholder":_vm.$t('myCard.exchangeCodeTip')},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing)return;_vm.code=$event.target.value}}})]),_c('span',{staticClass:"exchange-btn",on:{"click":_vm.exchange}},[_vm._v(_vm._s(_vm.$t('myCard.exchange')))])]:[_c('div',{staticClass:"exchange-title"},[_vm._v(_vm._s(_vm.$t("myCard.checkCoupon")))]),_c('div',{staticClass:"p"},[_vm._v(_vm._s(_vm.$t("myCard.hasCoupon")))]),_c('div',{staticClass:"coupon-list"},[_c('div',{staticClass:"each flex",staticStyle:{"width":"100%"}},[(
              _vm.couponOption.max_discount_price &&
              _vm.couponOption.max_discount_price[_vm.couponCurrentCode]
            )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("myCard.maxNum"))+" "+_vm._s(_vm.couponCurrentCode === "KRW" ? "₩" : _vm.couponCurrentCode)+_vm._s(_vm.couponOption.max_discount_price[_vm.couponCurrentCode])+" ")]):_vm._e(),_c('div',[_c('div',{staticClass:"c-name"},[_c('span',[_vm._v(_vm._s(_vm.couponOption.name)+" ")]),(false)?_c('div',{staticClass:"c-question",on:{"click":function($event){$event.stopPropagation();return _vm.showCouponInfo(_vm.couponOption)}}},[_c('img',{attrs:{"src":require("@/assets/images/mycard/question-circle.png"),"alt":""}})]):_vm._e()]),(
                _vm.couponOption.price_condition &&
                _vm.couponOption.price_condition[_vm.couponCurrentCode]
              )?_c('p',{staticClass:"min-num"},[_vm._v(" "+_vm._s(_vm.$t("myCard.useType", { amount: _vm.couponCurrentCode === "KRW" ? "₩" + _vm.couponOption.price_condition[_vm.couponCurrentCode] : _vm.couponCurrentCode + _vm.couponOption.price_condition[_vm.couponCurrentCode], }))+" ")]):_vm._e(),_c('p',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$t("myCard.expireAt"))+_vm._s(_vm.formatDate(_vm.couponOption.expire_at, "yyyy-MM-dd hh:mm:ss"))+" ")])]),_c('div',{staticClass:"c-num"},[_c('p',[(_vm.couponOption.can_share === 1)?_c('em',{staticClass:"share-tag"},[_vm._v(_vm._s(_vm.$t("myCard.share")))]):_vm._e(),_c('i',[_vm._v(_vm._s(100 - _vm.couponOption.discount)+"%")]),_vm._v(_vm._s(_vm.$t("myCard.disCount"))+" ")])])])]),_c('span',{staticClass:"exchange-btn",on:{"click":_vm.closeExchangeModal}},[_vm._v(_vm._s(_vm.$t("toast.comfirmBtn2")))])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }