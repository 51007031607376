<template>
  <!--  冻结优惠券弹窗  -->
  <div class="coupon-modal">
    <div class="coupon-main">
      <div class="coupon-close" @click="closeCouponModal">
        <img src="@/assets/images/close.png" alt="" />
      </div>
      <div class="coupon-title">
        {{
          continueDisabled ? $t("myCard.couponTips") : $t("myCard.couponTips2")
        }}
      </div>
      <div class="info">
        <p>{{ $t("myCard.useCoupon") }}{{ coupons }}</p>
        <div class="flex">
          <span
            >{{ $t("myCard.roleInfo") }}{{ couponOrder.server_name }} -
            {{ couponOrder.role_name }}</span
          >
          <span
            >{{ $t("myCard.originalAmount")
            }}{{
              couponOrder.currency_code === "KRW"
                ? "₩"
                : couponOrder.currency_code
            }}
            {{ couponOrder.original_amount }}</span
          >
          <span
            >{{ $t("myCard.productSubject")
            }}{{ couponOrder.product_subject || "-" }}</span
          >
          <span
            >{{ $t("myCard.payAmount")
            }}{{
              couponOrder.currency_code === "KRW"
                ? "₩"
                : couponOrder.currency_code
            }}
            {{ couponOrder.amount }}</span
          >
        </div>
      </div>
      <div class="btns">
        <span :class="continueDisabled ? 'submit' : ''" @click="payClose">{{
          $t("myCard.couponBtn1")
        }}</span>
        <span class="submit" @click="submit" v-if="!continueDisabled">{{
          $t("myCard.couponBtn2")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { payClose } from "@/api/mycard";
import { getLocalStorage } from "@/utils/stage";

export default {
  name: "couponModal",
  props: {
    couponOrder: {},
  },
  data() {
    return {};
  },
  computed: {
    coupons() {
      let str = "";
      this.couponOrder.coupons &&
        this.couponOrder.coupons.forEach((item, index) => {
          if (index > 0) {
            str = str + " ," + item.name;
          } else {
            str = str + item.name;
          }
        });
      return str;
    },
    showBtn() {
      return this.couponOrder.project_id == getLocalStorage("projectId");
    },
    continueDisabled() {
      return this.couponOrder.pay_way_type === "22";
    },
  },
  methods: {
    closeCouponModal() {
      this.$emit("closeCouponModal", false);
    },
    payClose() {
      this.$parent.showLoading = true;
      payClose({
        out_trade_no: this.couponOrder.out_trade_no,
        reason: "",
      })
        .then(() => {
          this.$parent.showLoading = false;
          this.$parent.message = this.$t("myCard.orderCancel");
          this.$parent.myCoupon();
          this.closeCouponModal();
        })
        .catch((error) => {
          this.$parent.showLoading = false;
          this.$parent.message = error.message;
        });
    },
    submit() {
      if (this.couponOrder.pay_way_type === "12") {
        //  gash1
        this.$parent.out_trade_no = this.couponOrder.out_trade_no;
        this.$parent.showPayStatus = true;
        let formData = `<form name="form1" id="form1" action=${this.couponOrder.transaction_url} method="post">
                  <input type="hidden" name="data" value=${this.couponOrder.auth_code}>
                  <input type="submit" value="send">
                </form>`;
        let newWindow = window.open("", "catPay");
        newWindow.document.write("<html><head></head><body>");
        let div = newWindow.document.createElement("div");
        div.innerHTML = formData;
        newWindow.document.body.appendChild(div);
        div.style.display = "none";
        newWindow.document.form1.submit();
      } else if (this.couponOrder.pay_way_type === "15") {
        //  paypal
        this.$parent.out_trade_no = this.couponOrder.out_trade_no;
        this.$parent.showPayStatus = true;
        window.open(this.couponOrder.transaction_url, "catPay");
      } else if (this.couponOrder.pay_way_type === "22") {
        //  payletter
        this.$parent.out_trade_no = this.couponOrder.out_trade_no;
        this.$parent.showPayStatus = true;
        window.open(this.couponOrder.transaction_url, "catPay");
      } else if (this.couponOrder.pay_way_type === "11") {
        //  myCard
        this.$parent.out_trade_no = this.couponOrder.out_trade_no;
        this.$parent.showPayStatus = true;
        window.open(this.couponOrder.transaction_url, "catPay");
      }
    },
  },
};
</script>
